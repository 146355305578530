import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { PrimaryButton, SecondaryButton } from '@primeit/react-core'

import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Stack, TextField, Typography } from '@mui/material'
import { SnackbarOrigin } from '@mui/material/Snackbar/Snackbar'

import {
  DepartmentForm,
  DepartmentFormSchema,
} from '@/api/departments/departments.types'
import { useNotification } from '@/hooks/useNotification'
import { getFieldNamesFromZodError } from '@/shared/utils/zod-field-names.utils'

interface DepartmentProps {
  department?: {
    id?: string
    name: string
  }
  onSubmit: (input: DepartmentForm) => void
  onCancel: () => void
}

const notificationAnchor: SnackbarOrigin = {
  vertical: 'top',
  horizontal: 'right',
}

export function DepartmentEditor({
  department,
  onSubmit,
  onCancel,
}: DepartmentProps) {
  const { t } = useTranslation('departments')
  const { addNotification } = useNotification()

  const form = useForm<DepartmentForm>({
    resolver: zodResolver(DepartmentFormSchema),
    defaultValues: {
      name: department?.name ?? '',
    },
  })
  const { handleSubmit, register } = form

  const isEdit = Boolean(department)
  const isCreate = !isEdit

  const onError = (error: unknown) => {
    addNotification({
      type: 'error',
      message: t('messages.form_field_errors', {
        fields: getFieldNamesFromZodError(error)
          .map((column) => t(`columns.${column}`))
          .join(', '),
      }),
      anchor: notificationAnchor,
    })
  }

  return (
    <Box padding="0 8px 8px 8px">
      {isCreate && (
        <Typography variant="h6" pb="20px">
          {t('labels.add')}
        </Typography>
      )}

      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <FormProvider {...form}>
          {isCreate && (
            <Box flex={0.75} pb="20px">
              <TextField {...register('name')} label={t('columns.name')} />
            </Box>
          )}

          {isEdit && (
            <Box flex={0.75} pb="20px">
              <TextField {...register('name')} label={t('columns.name')} />
            </Box>
          )}

          <Stack direction="row" gap={1}>
            <PrimaryButton
              type="submit"
              size="small"
              disabled={!form.formState.isDirty}
            >
              {t('labels.save')}
            </PrimaryButton>
            <SecondaryButton size="small" onClick={onCancel} disableElevation>
              {t('labels.cancel')}
            </SecondaryButton>
          </Stack>

          {/* <DebugObject debug={formState} title="Form state" /> */}
        </FormProvider>
      </form>
    </Box>
  )
}
