import { z } from 'zod'

import { apiClient } from '@/api/client'

export enum CustomFieldType {
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  SELECT = 'select',
  DS_SELECT = 'ds_select',
}

const CustomFieldSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  ordering: z.number(),
  type: z.nativeEnum(CustomFieldType),
  helperTable: z
    .object({
      id: z.string().uuid(),
    })
    .nullable(),
})

export type CustomFieldObjectType = z.infer<typeof CustomFieldSchema>

export const getCustomFields = async () => {
  const response =
    await apiClient.get<CustomFieldObjectType[]>('/custom-fields')
  return z.array(CustomFieldSchema).parse(response.data)
}
