import { z } from 'zod'

const DepartmentSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
})

export const DepartmentFormSchema = DepartmentSchema.omit({
  id: true,
})

export type DepartmentForm = z.infer<typeof DepartmentFormSchema>
export type Department = z.infer<typeof DepartmentSchema>
