import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useHasPermissions } from '@primeit/react-auth'
import {
  IconButtonBox,
  ModalConfirm,
  SecondaryButton,
  useClosure,
} from '@primeit/react-core'

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Card, CardContent, SnackbarOrigin, Stack } from '@mui/material'

import { Department, DepartmentForm } from '@/api/departments/departments.types'
import {
  useDeleteDepartment,
  useEditDepartment,
} from '@/api/departments/service'
import { useNotification } from '@/hooks/useNotification'
import { CardTile } from '@/pages/CareerPlan/CareerPlanManagement/components/styles'

import { DepartmentEditor } from './department-editor.component'

interface DepartmentItemProps {
  item: Department
}

const notificationAnchor: SnackbarOrigin = {
  vertical: 'top',
  horizontal: 'right',
}

export function DepartmentItem({ item }: DepartmentItemProps) {
  const { t } = useTranslation('departments')
  const { addNotification } = useNotification()

  const [isEdit, setEdit] = useState(false)
  const canEdit = useHasPermissions(['departments:write'])
  const canDelete = useHasPermissions(['departments:delete'])

  const switchEditing = useCallback(() => {
    if (canEdit) setEdit(!isEdit)
  }, [canEdit, isEdit])

  const { mutateAsync: editMutateAsync } = useEditDepartment()
  const { mutateAsync: deleteMutateAsync } = useDeleteDepartment()
  const onSubmit = (data: DepartmentForm) => {
    void editMutateAsync(
      { id: item.id, name: data.name } as unknown as Department,
      {
        onSuccess: () => {
          setEdit(false)
          void addNotification({
            type: 'success',
            message: t('messages.department_saved'),
            anchor: {
              vertical: 'top',
              horizontal: 'right',
            },
          })
        },
        onError: () => {
          void addNotification({
            type: 'error',
            message: t('messages.error_saving_department'),
            anchor: {
              vertical: 'top',
              horizontal: 'right',
            },
          })
        },
      }
    )
  }

  const onDeleteDepartment = () => {
    void deleteMutateAsync(item.id, {
      onSuccess: () => {
        addNotification({
          type: 'success',
          message: t('messages.department_removed'),
          anchor: notificationAnchor,
        })
      },
      onError: () => {
        addNotification({
          type: 'error',
          message: t('messages.department_removal_error'),
          anchor: notificationAnchor,
        })
      },
    })
  }

  const {
    isOpen: isDeleteDepartmentOpen,
    onOpen: onOpenDeleteDepartment,
    onClose: onCloseDeleteDepartment,
  } = useClosure()

  const onCancel = () => {
    setEdit(false)
  }

  return (
    <Card elevation={0}>
      <CardContent sx={{ p: '12px !important' }}>
        {!isEdit && (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <CardTile>{item.name}</CardTile>
              </Box>
              <Box display="flex" gap={1}>
                {canEdit && (
                  <SecondaryButton
                    disableElevation
                    startIcon={<EditIcon />}
                    onClick={switchEditing}
                    size="small"
                  >
                    {t('labels.edit')}
                  </SecondaryButton>
                )}

                {canDelete && (
                  <IconButtonBox
                    size="small"
                    variant="outlined"
                    color="secondary"
                    sx={{
                      borderColor: 'secondary.main',
                      '&:hover': {
                        backgroundColor: 'pink',
                        borderColor: 'pink',
                        color: 'error.main',
                      },
                    }}
                    onClick={onOpenDeleteDepartment}
                  >
                    <DeleteOutlineIcon
                      sx={{ color: 'lightGrey.contrastText', fontSize: 20 }}
                    />
                  </IconButtonBox>
                )}
              </Box>
            </Stack>
          </>
        )}

        {isEdit && (
          <DepartmentEditor
            department={item}
            onSubmit={onSubmit}
            onCancel={onCancel}
          />
        )}

        <ModalConfirm
          text={t('messages.confirm_department_removal')}
          title={t('translation:labels.warning')}
          isOpen={isDeleteDepartmentOpen}
          onClose={onCloseDeleteDepartment}
          onConfirm={onDeleteDepartment}
          maxWidth="xs"
        />
      </CardContent>
    </Card>
  )
}
