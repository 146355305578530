export enum Permission {
  EmployeesReadSageNumber = 'employees:read:sage_number',
  EmployeesExportData = 'employees:read:export',
  EmployeesReadHistory = 'employees:read:history',
  EmployeesReadFollowUp = 'employees:read:follow-up',
  EmployeesReadSalary = 'employees:read:salary',
  EmployeesRead = 'employees:read',
  EmployeesReadManagement = 'employees:read:management',
  EmployeesReadClient = 'employees:read:client',
  EmployeesReadContract = 'employees:read:contract',
  EmployeesWrite = 'employees:write',
  EmployeesInactivate = 'employees:inactivate',
  AnalyticsView = 'analytics:view',
  DevAccess = 'dev:access',
  SettingsView = 'settings:view',
  CareerPlanRead = 'career-plan:read',
  CareerPlanReadEmployees = 'career-plan:read:employees',
  ReportsRead = 'reports:read',
  ReportsWrite = 'reports:write',
  EmployeesWriteFollowUps = 'employees:write:follow-up',
  EmployeesReadContacts = 'employees:read:contacts',
  EmployeesReadConnections = 'employees:read:connections',
  DepartmentsRead = 'departments:read',
  DepartmentsWrite = 'departments:write',
  DepartmentsDelete = 'departments:delete',
}
