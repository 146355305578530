import { useTranslation } from 'react-i18next'
import { Link, LinkProps } from 'react-router'

import { PrimaryButton } from '@primeit/react-core'

import AddIcon from '@mui/icons-material/Add'

export function AddDepartmentsButton() {
  const { t } = useTranslation('departments')

  return (
    <PrimaryButton<typeof Link, LinkProps>
      component={Link}
      to="/departments/create"
      startIcon={<AddIcon fontSize="inherit" />}
    >
      {t('labels.add')}
    </PrimaryButton>
  )
}
