import { TableCell } from '@primeit/react-core'

import styled from '@emotion/styled'
import { TextField, Typography } from '@mui/material'

export const CardTile = styled(Typography)(() => ({
  backgroundColor: '#F2F2F2',
  fontSize: '1rem',
  padding: '0.44rem 0.62rem',
  borderRadius: '0.25rem',
  fontWeight: 700,
}))

export const CareerPlanCell = styled(TableCell)(() => ({
  backgroundColor: 'transparent',
}))

export const CareerPlanField = styled(TextField, {
  shouldForwardProp: (propName) => !['isFirst', 'isLast'].includes(propName),
})<{
  isFirst?: boolean
  isLast?: boolean
}>(({ theme, isFirst, isLast }) => ({
  '&.MuiFormControl-root': {
    ' .MuiInputBase-input': {
      borderColor: `${theme.palette.secondary.light}`,
      borderWidth: 1,
      padding: '1rem 0.5rem',
      boxSizing: 'border-box',
      fontSize: '0.75rem',
      ':hover': {
        borderColor: `${theme.palette.secondary.main}`,
        borderWidth: 1,
      },
    },
    ' .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.secondary.light}`,
      borderWidth: 1,
      borderRadius: 0,
      ...(isFirst
        ? {
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px',
          }
        : {}),
      ...(isLast
        ? {
            borderTopRightRadius: '4px',
            borderBottomRightRadius: '4px',
          }
        : {}),
    },
    ':hover': {
      ' .MuiOutlinedInput-notchedOutline': {
        borderColor: `${theme.palette.secondary.main}`,
        borderWidth: 1,
      },
    },
  },
}))
