import { nanoid } from 'nanoid'
import { useRecoilState } from 'recoil'
import { type INotification, notificationAtom } from 'src/atoms/notifications'

interface IUseNotification {
  addNotification: (notification: Omit<INotification, 'id'>) => void
  dismissNotification: (id: INotification['id']) => void
}

export function useNotification(): IUseNotification {
  const [, setNotifications] = useRecoilState(notificationAtom)

  const addNotification = ({
    autoHideDuration = 6000,
    anchor = { vertical: 'top', horizontal: 'center' },
    ...notification
  }: Omit<INotification, 'id'>): void => {
    setNotifications((current) => [
      ...current,
      { id: nanoid(), ...notification, autoHideDuration, anchor },
    ])
  }

  const dismissNotification = (id: INotification['id']): void => {
    setNotifications((current) =>
      current.filter((notification) => notification.id !== id)
    )
  }

  return { addNotification, dismissNotification }
}
