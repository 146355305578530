import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { Modal } from '@primeit/react-core'

import { Department, DepartmentForm } from '@/api/departments/departments.types'
import { useCreateDepartment } from '@/api/departments/service'
import { useNotification } from '@/hooks/useNotification'
import { useTitle } from '@/hooks/useTitle'

import { DepartmentEditor } from './components/department-editor.component'

export function AddDepartmentPage() {
  const navigate = useNavigate()
  const { addNotification } = useNotification()
  const { t } = useTranslation('departments')
  useTitle('Departments Management')

  const { mutateAsync: createAsync } = useCreateDepartment()

  const onClose = () => {
    void navigate('/departments')
  }

  const onSubmit = (formData: DepartmentForm) => {
    void createAsync(formData as unknown as Department, {
      onSuccess: () => {
        onClose()
        void addNotification({
          type: 'success',
          message: t('messages.department_added'),
          anchor: {
            vertical: 'top',
            horizontal: 'right',
          },
        })
      },
      onError: () => {
        void addNotification({
          type: 'error',
          message: t('messages.error_saving_department'),
          anchor: {
            vertical: 'top',
            horizontal: 'right',
          },
        })
      },
    })
  }

  return (
    <Modal isOpen onClose={onClose} maxWidth="sm" fullWidth disableCloseButton>
      <DepartmentEditor onSubmit={onSubmit} onCancel={onClose} />
    </Modal>
  )
}
