import { IndexParam, IndexResponseType, useIndex } from '@primeit/react-core'

import { useMutation } from '@tanstack/react-query'

import { apiClient } from '@/api/client'
import { queryClient } from '@/query-client'

import { Department } from './departments.types'

const getDepartments = async (
  params: IndexParam & { page: unknown }
): Promise<IndexResponseType<Department>> => {
  const response = await apiClient.get<IndexResponseType<Department>>(
    '/departments',
    {
      params,
    }
  )
  return response.data
}

export const useDepartments = (params: IndexParam) =>
  useIndex<Department>('departments', params, getDepartments)

const createDepartment = async ({ name }: Department) => {
  await apiClient.post(`/departments`, {
    name,
  })
}

export const useCreateDepartment = () =>
  useMutation({
    mutationKey: ['create_department'],
    mutationFn: createDepartment,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ['index_department'],
        exact: false,
      })
    },
  })

const editDepartment = async ({ id, name }: Department) => {
  await apiClient.put(`/departments/${id}`, {
    name,
  })
}

export const useEditDepartment = () =>
  useMutation({
    mutationKey: ['edit_department'],
    mutationFn: editDepartment,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ['index_departments'],
        exact: false,
      })
    },
  })

const deleteDepartment = async (roleId: string) => {
  await apiClient.delete(`/delete/${roleId}`)
}

export const useDeleteDepartment = () =>
  useMutation({
    mutationKey: ['delete_department'],
    mutationFn: deleteDepartment,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ['index_departments'],
        exact: false,
      })
    },
  })
