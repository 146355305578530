import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router'

import { useHasPermissions } from '@primeit/react-auth'
import {
  InfiniteScroll,
  SimpleTableColumnDefinition,
  Toolbar,
} from '@primeit/react-core'

import {
  Box,
  Card,
  CardContent,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'

import { Department } from '@/api/departments/departments.types'
import { useDepartments } from '@/api/departments/service'
import { Permission } from '@/shared/enums'

import { AddDepartmentsButton } from './components/add-department-button.component'
import { DepartmentItem } from './components/department-item'
import { NoContent } from './components/no-content'

const useColumns = (): SimpleTableColumnDefinition<Department>[] => {
  const { t } = useTranslation('departments')
  return [
    {
      field: 'name',
      label: t('columns.name'),
    },
  ] as SimpleTableColumnDefinition<Department>[]
}

export function DepartmentsPage() {
  const { t } = useTranslation('departments')

  const columns = useColumns()
  const { list, isLoading, isFetching, isError, hasNextPage, fetchNextPage } =
    useDepartments({})
  const canEdit = useHasPermissions([Permission.DepartmentsWrite])

  const isLoadingOrFetching = isLoading || isFetching

  console.log('departments:', list)

  return (
    <>
      <div
        style={{
          width: '100%',
        }}
      >
        <Toolbar>
          <Typography
            sx={{
              color: '#262626',
              fontSize: '1rem',
              fontWeight: 700,
            }}
            variant="subtitle1"
          >
            {t('labels.title')}
          </Typography>
          <Box ml="auto" />
          {canEdit && <AddDepartmentsButton />}
        </Toolbar>

        {!list.length && !isLoadingOrFetching && <NoContent />}

        {!isError && list.length > 0 && (
          <InfiniteScroll
            dataLength={list.length}
            onEndReached={() => void fetchNextPage()}
            isLoading={isLoadingOrFetching}
            hasMore={hasNextPage}
          >
            <Box display="flex" flexDirection="column" flex={1} gap={1}>
              {isLoadingOrFetching ? (
                <Card elevation={0}>
                  <CardContent>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      marginBottom="1.5rem"
                    >
                      <Box>
                        <Skeleton width={200} variant="text" />
                      </Box>
                    </Stack>
                  </CardContent>
                </Card>
              ) : (
                list.map((item, index) => (
                  <DepartmentItem key={`${item.id}_${index}`} item={item} />
                ))
              )}
            </Box>
          </InfiniteScroll>
        )}
      </div>
      <Outlet />
    </>
  )
}
