import { useTranslation } from 'react-i18next'

import { Box, Typography } from '@mui/material'

export function NoContent() {
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        width: 'initial',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '3rem',
      }}
    >
      <Box>
        <Typography variant="caption">{t('messages.no_content')}</Typography>
      </Box>
    </Box>
  )
}
