import { useMemo } from 'react'
import { initReactI18next, useTranslation } from 'react-i18next'

import { enUS, pt } from 'date-fns/locale'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import availableTranslations from '@/i18n/labels'

void i18n
  .use(initReactI18next)
  .use(LanguageDetector) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: availableTranslations,
    fallbackLng: 'EN',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    detection: {
      // Specify the custom local storage key
      lookupLocalStorage: `${import.meta.env.PROD ? 'production' : 'dev'}_locale`,
      // Other detection options
      order: [
        'querystring',
        'localStorage',
        'sessionStorage',
        'navigator',
        'htmlTag',
      ],
      caches: ['localStorage'],
    },
  })

export const useCurrentLocale = () => {
  const { i18n } = useTranslation()

  const { language } = i18n

  return useMemo(() => {
    switch (language) {
      case 'EN':
        return enUS
      default:
        return pt
    }
  }, [language])
}

export default i18n
