import { parseISO } from 'date-fns'
import { isValidPhoneNumber } from 'libphonenumber-js'

import { IndexObjectSchema } from '@/api/common'
import {
  BillingEnum,
  GenderEnum,
  IntegrationStatus,
  LanguageEnum,
  StatusEnum,
} from '@/shared/enums'
import { dateToStringTransformer } from '@/shared/utils'
import {
  nullableStringTransformer,
  stringToNumberTransformer,
} from '@/shared/utils/zod-string-date-transform.utils'

import { z } from '../../config/zod.config'
import i18n from '../../i18n/index'


export const DependentSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  birthDate: z.string().transform((str) => parseISO(str)),
  age: z.number(),
})

export const SimpleEmployee = z.object({
  id: z.string().uuid(),
  fullName: z.string().optional(),
  name: z.string().optional(),
})

export const EmployeeSchema = z.object({
  id: z.string().uuid().optional().nullable(),
  fullName: z.string().optional(),
  initials: z.string().optional().nullable(),
  nickname: z.string().optional().nullable(),
  criminalRecord: z.string().optional().nullable(),
  birthDate: z
    .string()
    .transform((str) => parseISO(str))
    .optional()
    .nullable(),
  externalId: z.string().optional().nullable(),
  age: z.number().optional().nullable(),
  firstDayOfContract: z
    .string()
    .transform((str) => parseISO(str))
    .optional()
    .nullable(),
  yearsOfService: z.number().optional().nullable(),
  dependents: DependentSchema.array().optional().nullable(),
  nationality: IndexObjectSchema.optional().nullable(),
  personalPhone: z.string().optional().nullable(),
  workPhone: z.string().optional().nullable(),
  personalEmail: z.string().optional().nullable(),
  workEmail: z.string().optional().nullable(),
  address: z.string().optional().nullable(),
  country: IndexObjectSchema.optional().nullable(),
  educationLevel: IndexObjectSchema.optional().nullable(),
  office: IndexObjectSchema.nullable(),
  tenant: IndexObjectSchema,
  managementOffice: IndexObjectSchema.optional().nullable(),
  contract: IndexObjectSchema.merge(
    z.object({
      iefp: z.boolean(),
      expirationDays: z.number().nullable(),
      effectiveDays: z.string().optional().nullable(),
      paidLeave: z.boolean(),
      foodTicket: z.boolean(),
    })
  )
    .optional()
    .nullable(),
  contractStartDate: z
    .string()
    .transform((str) => parseISO(str))
    .optional()
    .nullable(),
  endOfTrialPeriodDate: z
    .string()
    .transform((str) => parseISO(str))
    .optional()
    .nullable(),
  contractEndDate: z
    .string()
    .transform((str) => parseISO(str))
    .optional()
    .nullable(),
  projectStartDate: z
    .string()
    .transform((str) => parseISO(str))
    .optional()
    .nullable(),
  invoiceStartDate: z
    .string()
    .transform((str) => parseISO(str))
    .optional()
    .nullable(),
  effectiveDate: z
    .string()
    .transform((str) => parseISO(str))
    .optional()
    .nullable(),
  client: IndexObjectSchema.optional().nullable(),
  role: IndexObjectSchema.merge(
    z.object({
      department: IndexObjectSchema.optional().nullable(),
      enableCareerPlan: z.boolean().optional().nullable(),
    })
  )
    .optional()
    .nullable(),
  peopleOfficer: SimpleEmployee.optional().nullable(),
  billing: z.nativeEnum(BillingEnum).optional().nullable(),
  department: IndexObjectSchema.optional().nullable(),
  status: z.nativeEnum(StatusEnum).optional().nullable(),
  financialStatus: IndexObjectSchema.optional().nullable(),
  notes: z.string().optional().nullable(),
  gender: z.nativeEnum(GenderEnum).optional().nullable(),
  zip: z.string().optional().nullable(),
  region: z.string().optional().nullable(),
  team: IndexObjectSchema.optional().nullable(),
  ac: z.number().optional().nullable(),
  foodTicket: z.boolean().optional().nullable(),
  carTicket: z.boolean().optional().nullable(),
  iht: z.number().optional().nullable(),
  childTicket: z.number().optional().nullable(),
  una: z.number().optional().nullable(),
  businessManager: SimpleEmployee.optional().nullable(),
  businessUnitManager: SimpleEmployee.optional().nullable(),
  language: z.nativeEnum(LanguageEnum).optional().nullable(),
  project: IndexObjectSchema.merge(z.object({ clientId: z.string().uuid() }))
    .optional()
    .nullable(),
  leavingReason: IndexObjectSchema.optional().nullable(),
  rate: z.number().optional().nullable(),
  iefp: z.boolean().optional().nullable(),
  disabilities: z.boolean().optional().nullable(),
  paidLeave: z.boolean().optional(),
  mainSkill: IndexObjectSchema.optional().nullable(),
  syncData: z
    .array(
      z.object({
        status: z.nativeEnum(IntegrationStatus),
        message: z.record(z.string(), z.any()).nullable(),
        externalId: z.string(),
        type: z.string(),
        syncClient: z.object({
          slug: z.string(),
          name: z.string(),
        }),
      })
    )
    .optional()
    .nullable(),
  baseValue: z.number().nullable().optional(),
  totalRate: z.number().nullable().optional(),
  careerLevel: z
    .object({
      baseSalary: z.number(),
    })
    .nullish(),
  customFields: z
    .array(
      z.object({
        id: z.string().uuid(),
        customFieldId: z.string().uuid(),
        fieldValue: z.string().nullable(),
      })
    )
    .nullish(),
})

export type Employee = z.infer<typeof EmployeeSchema>

export const EmployeeEditResponseSchema = EmployeeSchema.merge(
  z.object({
    syncData: z.object({
      lockedFields: z.string().array(),
      clients: z.array(
        z.object({
          name: z.string(),
          externalId: z.string().nullish(),
          lockedFields: z.string().array(),
        })
      ),
    }),
  })
)

export type EmployeeEditResponseType = z.infer<
  typeof EmployeeEditResponseSchema
>

export const EmployeesResponse = EmployeeSchema.array()

export type EmployeesResponse = z.infer<typeof EmployeesResponse>

export const EmployeeFormSchema = EmployeeSchema.omit({
  id: true,
  businessManager: true,
  businessUnitManager: true,
  peopleOfficer: true,
  age: true,
  contract: true,
  client: true,
  country: true,
  department: true,
  gender: true,
  billing: true,
  language: true,
  role: true,
  office: true,
  managementOffice: true,
  currentTenant: true,
  project: true,
  nationality: true,
  status: true,
  leavingReason: true,
  financialStatus: true,
  team: true,
  birthDate: true,
  mainSkill: true,
  iht: true,
  childTicket: true,
  tenant: true,
  educationLevel: true,
  customFields: true,
  dependents: true,
})
  .partial({
    iefp: true,
  })
  .merge(
    z.object({
      id: z.string().uuid().nullable(),
      businessManager: SimpleEmployee.nullable(),
      businessUnitManager: SimpleEmployee.nullable(),
      peopleOfficer: SimpleEmployee.nullable(),
      contract: IndexObjectSchema.nullable(),
      client: IndexObjectSchema.nullable(),
      country: IndexObjectSchema.nullable(),
      department: IndexObjectSchema.nullable(),
      gender: z.nativeEnum(GenderEnum).nullable(),
      status: z.nativeEnum(StatusEnum).nullable(),
      billing: z.nativeEnum(BillingEnum).nullable(),
      language: z.nativeEnum(LanguageEnum).nullable(),
      role: IndexObjectSchema.nullable(),
      office: IndexObjectSchema.nullable(),
      managementOffice: IndexObjectSchema.nullable(),
      educationLevel: IndexObjectSchema.nullable(),
      project: IndexObjectSchema.nullable(),
      leavingReason: IndexObjectSchema.nullable(),
      financialStatus: IndexObjectSchema.nullable(),
      nationality: IndexObjectSchema.nullable(),
      team: IndexObjectSchema.nullable(),
      birthDate: z
        .string()
        .transform((str) => parseISO(str))
        .nullable(),
      mainSkill: IndexObjectSchema.nullable(),
      iht: stringToNumberTransformer.nullable(),
      childTicket: stringToNumberTransformer.nullable(),
      customFields: z.record(z.string().uuid(), z.string().nullish()),
    })
  )

export type EmployeeForm = z.infer<typeof EmployeeFormSchema>

export const EmployeeRequestSchema = EmployeeFormSchema
  // Makes fields optional
  .partial({
    iefp: true,
    workPhone: true,
  })
  // Transforms data
  .merge(
    z.object({
      id: z
        .string()
        .uuid()
        .transform(nullableStringTransformer)
        .nullable()
        .optional(),
      sageNumber: z
        .string()
        .transform(nullableStringTransformer)
        .nullable()
        .optional(),
      fullName: z
        .string()
        .trim()
        .min(1, { message: i18n.t('form_error_message.name') }),
      birthDate: dateToStringTransformer.nullable().optional(),
      firstDayOfContract: dateToStringTransformer.nullable().optional(),
      effectiveDate: dateToStringTransformer.nullable().optional(),
      projectStartDate: dateToStringTransformer.nullable().optional(),
      invoiceStartDate: dateToStringTransformer.nullable().optional(),
      contractStartDate: dateToStringTransformer.nullable().optional(),
      contractEndDate: dateToStringTransformer.nullable().optional(),
      endOfTrialPeriodDate: dateToStringTransformer.nullable().optional(),
      baseValue: stringToNumberTransformer.nullable(),
      ac: stringToNumberTransformer.nullable(),
      foodTicket: z.boolean(),
      carTicket: z.boolean(),
      iht: stringToNumberTransformer.nullable(),
      childTicket: stringToNumberTransformer.nullable(),
      una: stringToNumberTransformer.nullable(),
      rate: stringToNumberTransformer.nullable(),
      // TEMP - Marks all fields as required
      mainSkill: IndexObjectSchema.nullable().optional(),
      personalEmail: z
        .string()
        .email({ message: i18n.t('form_error_message.personal_email') })
        .or(z.literal(''))
        .transform(nullableStringTransformer),
      nationality: IndexObjectSchema.nullable().optional(),
      educationLevel: IndexObjectSchema.nullable().optional(),
      personalPhone: z
        .string()
        .optional()
        .nullable()
        .transform((val, ctx) => {
          if (!val) {
            return
          }

          const isValidNumber = isValidPhoneNumber(val)
          if (!isValidNumber) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: i18n.t('form_error_message.personal_phone'),
            })

            return z.NEVER
          }

          return val
        })
        .transform(nullableStringTransformer),
      gender: z.nativeEnum(GenderEnum).nullable().optional(),
      language: z.nativeEnum(LanguageEnum).nullable().optional(),
      address: z
        .union([z.string().length(0), z.string().trim().min(1)])
        .optional()
        .nullable()
        .transform(nullableStringTransformer),
      zip: z
        .string()
        .regex(/\d+(-\d+)?/, i18n.t('form_error_message.zip'))
        .or(z.literal(''))
        .transform(nullableStringTransformer),
      region: z
        .union([z.string().length(0), z.string().trim().min(1)])
        .optional()
        .nullable()
        .transform(nullableStringTransformer),
      country: IndexObjectSchema.nullable().optional(),
      department: IndexObjectSchema.nullable().optional(),
      role: IndexObjectSchema.nullable().optional(),
      project: IndexObjectSchema.nullable().optional(),
      workEmail: z
        .string()
        .email({ message: i18n.t('form_error_message.prime_email') })
        .or(z.literal(''))
        .transform(nullableStringTransformer),
      workPhone: z
        .string()
        .optional()
        .nullable()
        .transform((val, ctx) => {
          if (!val) {
            return
          }

          const isValidNumber = isValidPhoneNumber(val)
          if (!isValidNumber) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: i18n.t('form_error_message.prime_phone'),
            })

            return z.NEVER
          }

          return val
        })
        .transform(nullableStringTransformer),
      peopleOfficer: SimpleEmployee.nullable().optional(),
      businessManager: SimpleEmployee.nullable().optional(),
      businessUnitManager: SimpleEmployee.nullable().optional(),
      team: IndexObjectSchema.nullable().optional(),
      leavingReason: IndexObjectSchema.nullable().optional(),
      financialStatus: IndexObjectSchema.nullable().optional(),
      office: IndexObjectSchema.nullable().optional(),
      managementOffice: IndexObjectSchema.nullable().optional(),
      client: IndexObjectSchema.nullable().optional(),
      contract: IndexObjectSchema.nullable().optional(),
      billing: z.nativeEnum(BillingEnum).nullable().optional(),
      // TEMP
    })
  )
  // TEMP - MAKE ALL FIELDS REQUIRED
  .transform(
    ({
      nationality,
      country,
      office,
      managementOffice,
      role,
      department,
      mainSkill,
      businessManager,
      businessUnitManager,
      client,
      contract,
      peopleOfficer,
      project,
      team,
      leavingReason,
      financialStatus,
      educationLevel,
      ...val
    }) => ({
      ...val,
      nationalityId: nationality?.id ?? null,
      countryId: country?.id ?? null,
      officeId: office?.id ?? null,
      managementOfficeId: managementOffice?.id ?? null,
      roleId: role?.id ?? null,
      departmentId: department?.id ?? null,
      mainSkillId: mainSkill?.id ?? null,
      businessManagerId: businessManager?.id ?? null,
      businessUnitManagerId: businessUnitManager?.id ?? null,
      clientId: client?.id ?? null,
      contractId: contract?.id ?? null,
      peopleOfficerId: peopleOfficer?.id ?? null,
      projectId: project?.id ?? null,
      teamId: team?.id ?? null,
      leavingReasonId: leavingReason?.id ?? null,
      financialStatusId: financialStatus?.id ?? null,
      educationLevelId: educationLevel?.id ?? null,
    })
  )

export type EmployeeRequest = z.infer<typeof EmployeeRequestSchema>

interface Item {
  title: string
  total: number
}

export interface StatisticsResponse {
  status: Item[]
  consultants: Item[]
  peoplePerDepartment: Item[]
}

export interface GoalsResponse {
  id: number
  invoicedLisbon: number
  differenceLisbon: number
  invoicedPorto: number
  differencePorto: number
  invoicedTotal: number
  differenceTotal: number
  createdAt: string
  isActive: boolean
}

export const InactivateRequestSchema = z.object({
  employeeId: z.string().uuid(),
  leavingReasonId: z.string().uuid(),
  contractEndDate: z.date(),
  note: z.string().nullable(),
})

export type InactivateRequestType = z.infer<typeof InactivateRequestSchema>

export const InactivateRequestForm = InactivateRequestSchema.omit({
  employeeId: true,
  leavingReasonId: true,
}).merge(
  z.object({
    leavingReason: IndexObjectSchema,
  })
)

export const InactivateFormSchema = InactivateRequestForm.partial()

export type InactivateFormType = z.infer<typeof InactivateFormSchema>

export const EmployeeHistorySchema = z.object({
  id: z.string().uuid(),
  createdAt: z.string().datetime(),
  type: z.string(),
  label: z.string().nullable(),
  contract: z
    .object({
      name: z.string(),
    })
    .nullable(),
  role: z
    .object({
      name: z.string(),
    })
    .nullable(),
  careerLevel: z
    .object({
      name: z.string(),
    })
    .nullable(),
})

export type EmployeeHistoryType = z.infer<typeof EmployeeHistorySchema>

export const EmployeeHistoryResponseSchema = z.array(EmployeeHistorySchema)

export type EmployeeHistoryResponseType = z.infer<
  typeof EmployeeHistoryResponseSchema
>

const FollowUpSchema = z.object({
  id: z.string().uuid(),
  content: z.string(),
  createdAt: z.string().datetime(),
  type: z.string(),
  createdBy: z
    .object({
      name: z.string(),
    })
    .nullable(),
})

export type FollowUpType = z.infer<typeof FollowUpSchema>

export const FollowUpListSchema = z.array(FollowUpSchema)

export const AddFollowUpRequest = z.object({
  id: z.string().uuid(),
  content: z.string(),
})

export type AddFollowUpFormType = z.infer<typeof AddFollowUpRequest>

export const EmployeeLinkSchema = z.object({
  fullName: z.string(),
  workEmail: z.string(),
  id: z.string(),
  status: z.nativeEnum(StatusEnum),
})

export type EmployeeLinkType = z.infer<typeof EmployeeLinkSchema>

export const EmployeeContactSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  phone: z.string(),
})

export type EmployeeContactType = z.infer<typeof EmployeeContactSchema>

export const EmployeeContactFormSchema = z.object({
  id: z.string().uuid().nullish(),
  name: z.string(),
  phone: z.string(),
})

export type EmployeeContactFormType = z.infer<typeof EmployeeContactFormSchema>

export const EmployeeDependentSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  birthDate: z.string().transform((str) => parseISO(str)),
  age: z.number(),
})

export type EmployeeDependentType = z.infer<typeof EmployeeDependentSchema>

export const EmployeeDependentFormSchema = z.object({
  id: z.string().uuid().nullish(),
  name: z.string().trim().min(1),
  birthDate: dateToStringTransformer,
})

export type EmployeeDependentFormType = z.infer<
  typeof EmployeeDependentFormSchema
>
