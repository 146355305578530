import { useEffect } from 'react'

import { useCurrentTenant } from '@/atoms/tenant.atom'

export const useTitle = (title: string) => {
  const tenant = useCurrentTenant()
  useEffect(() => {
    const ogTitle = document.title
    document.title = `${title} | ${tenant?.name} - People Management`
    return () => {
      document.title = ogTitle
    }
  }, [title, tenant])
}
